<template>
  <div></div>
</template>
<script>
export default {
  name: "logout",
  data() {
    return {
    };
  },
  mounted(){
    this.$store.dispatch('logout')
  }
};
</script>
